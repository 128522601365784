import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Subscription = {
    getCustomerAgreement,
    getServices,
    processCardPaymentMethod,
    processPaypalPayment,
    getCustomerPlan,
    confirmPaypalPayment,
    cancelSubscription,
    cancelCustomerFeedback,
    getPlanDetails,
    processApplePayment
}

function getCustomerAgreement(){
    
    const url = 'subscription/get-customer-agreement.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    };
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function getServices(data){
    
    const url = 'subscription/get-services.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function processCardPaymentMethod(data){
    
    const url = 'subscription/process-card-payment.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function processPaypalPayment(data){
    
    const url = 'subscription/payment-method.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function getCustomerPlan(data){
    
    const url = 'subscription/get-customer-plan.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function confirmPaypalPayment(data){
    const url = 'subscription/confirm-paypal-payment.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function cancelSubscription(){
    const url = 'customer/cancel-billing.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function cancelCustomerFeedback(data){
    const url = 'subscription/send-customer-feedback.json?store_id=1&lang_code=en';
    data.token =  USER_TOKEN;
    
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function getPlanDetails(data){
    const url = 'subscription/get-services-details.json?store_id=1&lang_code=en';
    data.token =  USER_TOKEN;
    
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function processApplePayment(data){
    const url = 'subscription/process-applepay-payment.json?store_id=1&lang_code=en';
    data.token =  USER_TOKEN;
    
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
export default Subscription