import * as types from './type';

import { Uploads }  from  '../../services/uploads';

export function selectSong(data) {
    return {
      type: types.SELECT_SONG,
      payload: Uploads.selectSong(data),
    };
};

export function getUploadedSongs(data) {
  return {
    type: types.GET_UPOLOADED_SONGS,
    payload: Uploads.getUploadedSongs(data),
  };
};