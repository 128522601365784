import {axiosReqGet, axiosReqOther} from "./config";

import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Genres = {
  getHomeGenres,
  getGenresWithCount,
  getGenreSongs,
  getSongEditGenres,
  getCuratedGenresWithResult
}

function getHomeGenres(){
    const url = 'genres/home-page-genres.json?store_id=1&lang_code=en';
    return axiosReqGet()
        .get(url)
        .then(res => {
        return res; 
    })
    .catch(err => { 
        
        // alert(err.response.data.message)      
        return err; 
    })
}
    
function getGenresWithCount(){
    const url = 'genres/get-genres-with-count.json?store_id=1&lang_code=en&song_sectoin_id=1';
    return axiosReqGet()
        .get(url)
        .then(res => {
        return res; 
    })
    .catch(err => { 
        
        // alert(err.response.data.message)      
        return err; 
    })
}


function getGenreSongs(data){
    const url = 'genres/songs.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
    return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getSongEditGenres(data){
    const url = 'genres/get-song-genres.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
    return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getCuratedGenresWithResult(){
    const url = 'curated/curated-category-list.json?store_id=1&lang_code=en';
    const data = {token:  USER_TOKEN};
    return axiosReqOther(url, data, 'post').then(res => {              
    return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}


export default Genres;