import * as types from './type';

import { Genres }  from  '../../services/genres';

export function getHomeGenres() {
    return {
      type: types.GET_HOME_GENRES,
      payload: Genres.getHomeGenres(),
    };
};

export function getGenresWithCount() {
  return {
    type: types.GET_GENRES_WITH_COUNT,
    payload: Genres.getGenresWithCount(),
  };
};

export function getGenreSongs(post) {
  return {
    type: types.GET_GENRES_SONG,
    payload: Genres.getGenreSongs(post),
  };
};

export function getSongEditGenres(data){
  return {
    type: types.GET_EDIT_SONG_GENRES,
    payload: Genres.getSongEditGenres(data),
  };
}

export function getCuratedGenresWithResult(){
  return {
    type: types.GET_CURATED_GENRES,
    payload: Genres.getCuratedGenresWithResult(),
  };
}