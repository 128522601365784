import * as types from './type';

import { Crate }  from  '../../services/crate';

export function getCrateList(data) {
    return {
      type: types.GET_CRATE_LIST,
      payload: Crate.getCrateList(data),
    };
};

export function clearAllTrack() {
  return {
    type: types.CLEAR_CRATE_LIST,
    payload: Crate.clearAllTrack(),
  };
};

export function downloadBatchFiles(data) {
  return {
    type: types.DOWNLOAD_CRATE_LIST,
    payload: Crate.downloadBatchFiles(data),
  };
};