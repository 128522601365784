import axios from 'axios';

export const API_BASE_URL = 'https://sdemo.neverbeencool.com/v1/';

const BEARER_TOKEN = '9lkxxugui21yiwi2vlbgrs989dbbf8xc';

export const axiosReqGet = () => {
    return axios.create({
      baseURL: API_BASE_URL,
      headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer 9lkxxugui21yiwi2vlbgrs989dbbf8xc'},
      // timeout: 10000,
    })
}

export const axiosReqOther = (url, data, method) => {
    url = API_BASE_URL + url;
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url,
        data,
        headers: {
          Authorization: "Bearer 9lkxxugui21yiwi2vlbgrs989dbbf8xc" ,
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
};

export const axiosReqOtherAttachment = (url, data, method) => {
  url = API_BASE_URL + url;
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url,
      data,
      headers: {
        Authorization: "Bearer 9lkxxugui21yiwi2vlbgrs989dbbf8xc" ,
        "Content-Type": "multipart/form-data"
      }
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};