import * as types from './type';

import { Download }  from  '../../services/download';

export function downloadSongVersion(version) {
    return {
      type: types.DOWNLOAD_SONG_VERSION,
      payload: Download.downloadSongVersion(version),
    };
};

export function downloadCrateSongVersion(version) {
  return {
    type: types.DOWNLOAD_SONG_VERSION,
    payload: Download.downloadCrateSongVersion(version),
  };
};

export function downloadBundleSong(post) {
  return {
    type: types.DOWNLOAD_SONG_BUNDLE,
    payload: Download.downloadBundleSong(post),
  };
};

export function getMostDownloadedToday(post) {
  return {
    type: types.MOST_DOWNLOAD_SONG_TODAY,
    payload: Download.getMostDownloadedToday(post),
  };
};

export function getDownloadRescue(post){
  return {
    type: types.DOWNLOAD_HISTORY,
    payload: Download.getDownloadRescue(post),
  }
}

export function getCustomerDownloads(){
  return {
    type: types.CUSTOMER_DOWNLOADS,
    payload: Download.getCustomerDownloads(),
  }
}