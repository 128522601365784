import * as types from './type';

const initialState = {
  crateSongs: [],
  crateGenres: []
};



export default function crateReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_CRATE_LIST: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          crateSongs: action.payload.data.songs,
          crateGenres: action.payload.data.list
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.CLEAR_CRATE_LIST: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          crateSongs: action.payload.data.songs,
          crateGenres: action.payload.data.list
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.DOWNLOAD_CRATE_LIST: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          crateSongs: action.payload.data.songs,
          crateGenres: action.payload.data.list
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}