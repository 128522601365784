import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Download = {
    downloadSongVersion,
    downloadCrateSongVersion,
    downloadBundleSong,
    getMostDownloadedToday,
    getDownloadRescue,
    getCustomerDownloads
}


/**
 * method downloadSongVersion
 * @param {*} post 
 * return array
 */
function downloadSongVersion(post){
    post.token = USER_TOKEN;
    const url = 'download/song-version.json?store_id=1&lang_code=en';
    return axiosReqOther(url, post, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}


/**
 * method downloadCrateSongVersion
 * @param {*} post 
 * return array
 */
function downloadCrateSongVersion(post){
    post.token = USER_TOKEN;
    post.isCreate = true;
    const url = 'download/song-version.json?store_id=1&lang_code=en';
    return axiosReqOther(url, post, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
 * method downloadBundleSong
 * @param {*} post 
 * return array
 */
function downloadBundleSong(post){

    post.token = USER_TOKEN;
    const url = 'download/song-bundle.json?store_id=1&lang_code=en';
    return axiosReqOther(url, post, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
 * method: getMostDownloadedToday
 * @param {*} data 
 */
function getMostDownloadedToday(data){
    data.token = USER_TOKEN;
    const url = 'download/get-most-downloaded-today.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
    
}

/**
 * method: getDownloadRescue
 * @param {*} data 
 */
function getDownloadRescue(data){
    data.token = USER_TOKEN;
    const url = 'download/download-rescue.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
 * method: getCustomerDownloads
 * @param {*} data 
 */
function getCustomerDownloads(){
    const data = {token: USER_TOKEN};
    const url = 'download/customer-downloads.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}


export default Download;
