import * as types from './type';

import { Subscription }  from  '../../services/subscription';

export function getCustomerAgreement() {
    return {
      type: types.CUSTOMER_AGGREEMENT,
    payload: Subscription.getCustomerAgreement(),
    };
};

export function getServices(data) {
  return {
    type: types.MEMBERSHIP_PLANS,
    payload: Subscription.getServices(data),
  };
};

export function processCardPaymentMethod(data) {
  return {
    type: types.CREDIT_CARD_PAYMENT,
    payload: Subscription.processCardPaymentMethod(data),
  };
};

export function processPaypalPayment(data) {
  return {
    type: types.PAYPAL_RECURRING_PAYMENT,
    payload: Subscription.processPaypalPayment(data),
  };
};

export function getCustomerPlan(data) {
  return {
    type: types.GET_CUSTOMER_PLAN,
    payload: Subscription.getCustomerPlan(data),
  };
};

export function confirmPaypalPayment(data) {
  return {
    type: types.CONFIRM_PAYPAL_PAYMENT,
    payload: Subscription.confirmPaypalPayment(data),
  };
};

export function cancelSubscription(){
  return {
    type: types.CANCEL_SUBSCRIPTION,
    payload: Subscription.cancelSubscription(),
  };
}
export function cancelCustomerFeedback(data){
  return {
    type: types.CANCEL_SUBSCRIPTION_FEEDBACK,
    payload: Subscription.cancelCustomerFeedback(data),
  };
}
export function getPlanDetails(data){
  return {
    type: types.GET_SUBSCRIPTION_DETAILS,
    payload: Subscription.getPlanDetails(data),
  };
}
export function processApplePayment(data){
  return {
    type: types.PROCESS_MOBILE_PAYMENTS,
    payload: Subscription.processApplePayment(data),
  };
}